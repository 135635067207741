<div class="page-container field-set-container">
  <p-toast />
  <div class="header-container">
    <div class="title">
      <i
        class="pi pi-arrow-left"
        style="font-size: 1rem; cursor: pointer"
        (click)="navigateBack()"
      ></i>
      <div class="header">Field Sets</div>
    </div>
    <div style="display: flex; gap: 2rem" *ngIf="fieldSetList.length === 0">
      <app-button label="Add" (clickEvent)="handleAddNew()" />
    </div>
  </div>

  <p-table
    [columns]="cols"
    [value]="fieldSetList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td (click)="onAssessmentField($event, rowData)">
          <div class="field-names" (mouseover)="clearAssessment()">
            Assessment Fields
          </div>
          <p-popover #opAssessment>
            <ng-template pTemplate="content">
              <li *ngFor="let fields of this.assessmentField">
                {{ fields.field.code }}
              </li>
            </ng-template>
          </p-popover>
        </td>
        <td (click)="onInstallationField($event, rowData)">
          <div class="field-names" (mouseover)="clearInstallation()">
            Installation Fields
          </div>
          <p-popover #opInstallation>
            <ng-template pTemplate="content">
              <li *ngFor="let fields of this.installationField">
                {{ fields.field.code }}
              </li>
            </ng-template>
          </p-popover>
        </td>
        <td>
          <img src="assets/CalendarIcon.svg" style="margin-right: 5px" />
          {{ rowData.dateFrom | date : "yyyy-MM-dd" }}
        </td>
        <td style="width: 5%; text-align: end" (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-popover #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Amend</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                (click)="handleDelete(rowData)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-popover>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="fieldSetList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '50vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ editFields ? "Edit Field Sets" : "Add Field Sets" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div class="activites-container">
          <div class="activites-header">
            <h3></h3>
            <app-button label="Add" (clickEvent)="addtoFields()" />
          </div>
          <div
            *ngIf="fieldList.length !== 0"
            cdkDropList
            class="dragDrop-list"
            (cdkDropListDropped)="dropFields($event)"
          >
            @for (rowData of fieldList; track rowData) {
            <div class="dragDrop-box" cdkDrag>
              <div
                class="dragDrop-custom-placeholder"
                *cdkDragPlaceholder
              ></div>
              {{ rowData.code }}
            </div>
            }
          </div>
          <div *ngIf="fieldList.length === 0" class="noData">
            No Data found.
          </div>
        </div>

        <div style="width: 50%; margin: 1rem 0">
          <app-calendar
            [control]="formData.get('valid_from')"
            label="Valid From"
            [required]="true"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        [label]="editFields ? 'Update' : 'Save'"
        [disabled]="formData.invalid"
        (clickEvent)="editFields ? onAmend() : onSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="fieldsDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '50vw', 'max-height': '90vh', height: '70vh' }"
  >
    <ng-template pTemplate="header">
      <div style="display: flex; width: 100%; justify-content: space-between">
        <h3>Fields</h3>
        <app-search-text (searchEvent)="handleSearch($event)" />
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <p-table
        [columns]="fieldCols"
        dataKey="id"
        [value]="FieldsList"
        [(selection)]="selectedRows"
        [paginator]="FieldsList.length > 10 ? true : false"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[5, 10, 20]"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox />
            </th>
            <th pSortableColumn="label">Fields <p-sortIcon field="label" /></th>
            <th pSortableColumn="fieldType">
              Type <p-sortIcon field="fieldType" />
            </th>
            <th pSortableColumn="fieldType">
              Stage <p-sortIcon field="fieldType" />
            </th>
          </tr>

          <ng-container *ngIf="isFieldLoading">
            <tr *ngFor="let _ of skeletonRows">
              <th class="skeleton">
                <p-skeleton></p-skeleton>
              </th>
              <th class="skeleton" *ngFor="let col of columns">
                <p-skeleton></p-skeleton>
              </th>
              <th class="skeleton">
                <p-skeleton></p-skeleton>
              </th>
            </tr>
          </ng-container>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td>
              <p-tableCheckbox [value]="rowData" />
            </td>
            <td>{{ rowData.code }}</td>
            <td>{{ formatFieldType(rowData.fieldType) }}</td>
            <td>{{ rowData?.fieldStage }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" *ngIf="FieldsList.length === 0">
          <tr *ngIf="!isFieldLoading">
            <td colspan="3">No Data found.</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closFieldDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button label="Save" (clickEvent)="onFieldSave()" />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Field Set?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Delete"
        (clickEvent)="onDeleteFieldSet()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <!-- Hand Out Documents -->
  <div class="hand-out-document">
    <div class="header-container">
      <div class="header">Hand-Out Documents</div>
      <app-button
        label="Add"
        (clickEvent)="handleAddDocument()"
        class="header"
      />
    </div>

    <p-table
      [columns]="DocumentCols"
      dataKey="id"
      [value]="documentList"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      [rowsPerPageOptions]="[5, 10, 20]"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>

        <ng-container *ngIf="isLoading">
          <tr *ngFor="let _ of skeletonRows">
            <th class="skeleton" *ngFor="let col of columns">
              <p-skeleton></p-skeleton>
            </th>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td style="font-weight: 500">{{ rowData.title }}</td>
          <td>{{ rowData.version }}</td>
          <td>
            <a
              class="view-document"
              style="color: #235aff; cursor: pointer"
              (click)="downloadFile(rowData)"
            >
              View Document
            </a>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" *ngIf="documentList.length === 0">
        <tr *ngIf="!isLoading">
          <td colspan="5">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog
    header="Header"
    [(visible)]="documentDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ edit ? "Edit" : "Add" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="documentData">
        <div>
          <label class="input-label">Hand Out Documents</label>
          <p-multiSelect
            [options]="handOutDocuments"
            formControlName="documents"
            optionLabel="title"
            placeholder="Select"
            appendTo="body"
            optionValue="id"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Save"
        [disabled]="documentData.invalid"
        (clickEvent)="onDocumentSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>
