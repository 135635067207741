import { Component, OnInit } from '@angular/core';
import { MsalServiceData } from '../../Pages/landing-page/msal.service';
import { ButtonComponent } from '../../Components/button/button.component';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  imports: [ButtonComponent, SharedModule],
})
export class HeaderComponent implements OnInit {
  onHover: number = 0;

  constructor(private msalService: MsalServiceData) {}

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async ngOnInit(): Promise<any> {
    await this.msalService.createInstance();
    await this.msalService.initialize();
  }

  logout() {
    this.msalService.logout();
    localStorage.clear();
  }
}
