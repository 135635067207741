<div class="page-container aggregator-container">
  <p-toast />
  <div class="header-container">
    <app-search-text (searchEvent)="handleSearch($event)" class="header" />
    <app-button label="Add" (clickEvent)="handleAddDialog()" class="header" />
  </div>

  <p-table
    [columns]="cols"
    [value]="AggregatorList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
    dataKey="id"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template
      #body
      let-rowData
      let-columns="columns"
      let-expanded="expanded"
    >
      <tr>
        <td>
          <span
            pRipple
            [pRowToggler]="rowData"
            [class]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          ></span>
        </td>
        <td>
          <div class="aggregator-container">
            <img
              [src]="rowData.logoContent"
              width="24"
              class="vertical-align-middle"
            />
            <div style="font-size: 0.9rem">{{ rowData.name }}</div>
          </div>
        </td>
        <td>{{ rowData.abn }}</td>
        <td>
          <p-tag
            [value]="rowData.status"
            [severity]="statusTag(rowData.status)"
          />
        </td>
        <td style="width: 5%; text-align: end" (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-popover #op>
            <ng-template pTemplate="content">
              <div
                *ngIf="rowData.status === 'Offline'"
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>
              <div
                (mouseover)="handleHover(3)"
                (click)="handleInviteUser(rowData)"
                class="list-item"
                [class.hover-bg-light-green]="onHover === 3"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-plus" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Invite User</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                (click)="handleDelete(rowData)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-popover>
        </td>
      </tr>
    </ng-template>
    <ng-template #expandedrow let-AggregatorList>
      <tr>
        <td colspan="7">
          <div class="p-3">
            <p-table [columns]="userCols" [value]="AggregatorList.users">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns">{{ col.header }}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td>{{ rowData.name }}</td>
                  <td>
                    <div class="email-container">
                      <img src="assets/email.icon.svg" />
                      <div style="font-size: 0.9rem">{{ rowData.email }}</div>
                    </div>
                  </td>
                  <td>
                    <p-tag
                      [value]="rowData.status"
                      [severity]="statusTag(rowData.status)"
                    />
                  </td>
                  <td
                    style="width: 5%; text-align: end"
                    (click)="op.toggle($event)"
                  >
                    <img src="assets/ActionMenu.svg" />
                    <p-popover #op>
                      <ng-template pTemplate="content">
                        <div
                          (mouseover)="handleHover(2)"
                          (click)="handleUserDelete(rowData)"
                          class="list-item"
                          [class.hover-bg-light-red]="onHover === 2"
                          (mouseleave)="onHover = 0"
                        >
                          <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                          <span style="font-size: 14px">Deactive</span>
                        </div>
                      </ng-template>
                    </p-popover>
                  </td>
                </tr>
              </ng-template>

              <ng-template
                pTemplate="emptymessage"
                *ngIf="userList.length === 0"
              >
                <tr *ngIf="!isLoading">
                  <td colspan="5">No Data found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="AggregatorList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '40vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ edit ? "Edit Aggregator" : "Add Aggregator" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div class="grid-container">
          <div style="display: flex; gap: 0.5rem; align-items: center">
            <div style="width: 80%">
              <app-input
                label="ABN"
                [control]="formData.get('ABN')"
                [required]="true"
                (keyup.enter)="onEnter($event)"
              />
            </div>
            <div style="margin-top: 0.5rem">
              <app-button
                label="Search"
                (clickEvent)="onEnter(formData.get('ABN')?.value)"
              />
            </div>
          </div>

          <app-disabled-input
            label="Company Name"
            [control]="formData.get('name')"
            [required]="true"
          />
          <div style="padding-bottom: 1rem">
            <label class="input-label">
              Address
              <span class="required-label">*</span>
            </label>
            <p-autoComplete
              formControlName="area"
              [suggestions]="filteredAddress"
              optionLabel="address.freeformAddress"
              (completeMethod)="onSearchAddress($event)"
              [appendTo]="'body'"
            />

            <div
              class="error-message"
              *ngIf="
                formData.get('area')?.invalid && formData.get('area')?.touched
              "
            >
              Address is required.
            </div>
          </div>
          <div>
            <label class="input-label">Activities</label>
            <p-multiSelect
              [options]="Activities"
              formControlName="activities"
              optionLabel="schemeActivity"
              placeholder="Select"
              appendTo="body"
              optionValue="id"
            />
          </div>
          <app-input
            label="Contact Name"
            [control]="formData.get('contactName')"
            [required]="true"
          />
          <app-input
            label="Contact Email"
            [control]="formData.get('contactEmail')"
            [required]="true"
          />
          <app-input
            label="Contact Phone"
            [control]="formData.get('contactPhone')"
            [required]="true"
          />
          <div>
            <label class="input-label">Logo</label>
            <label
              (drop)="onFileDrop($event)"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              htmlFor="fileType"
            >
              <div class="file-text">Select or drag Image</div>
              <input
                type="file"
                (change)="onFileSelected($event)"
                id="fileType"
                style="display: none"
                accept="image/*"
              />
            </label>
            <div class="file-name" *ngIf="imageUrl != null">
              <img
                [src]="imageUrl"
                alt="logo"
                style="max-width: 100px; max-height: 100px"
              />
              <span
                class="pi pi-times"
                style="color: #e71d36; margin-left: 1rem"
                (click)="removeFile()"
              ></span>
            </div>
          </div>
        </div>
        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 1rem"
        >
          <app-button
            *ngIf="!edit"
            label="Save"
            [disabled]="formData.invalid"
            (clickEvent)="onSave()"
            [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
          />
          <app-button
            *ngIf="edit"
            label="Update"
            [disabled]="formData.invalid"
            (clickEvent)="onUpdate()"
            [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
          />
        </div>
      </form>
      <div style="margin-bottom: 2rem">
        <p-table [columns]="activitiesCols" [value]="selectedRows">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">{{ col.header }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pEditableRow]="rowData">
              <td>{{ rowData.schemeAndActivity }}</td>
              <td>{{ rowData.registrationCode || "-" }}</td>
              <td
                [pEditableColumn]="rowData.accreditationNumber"
                style="width: 25%"
              >
                <p-cellEditor
                  *ngIf="isRowEditable(rowData) && rowData.registrationCode"
                  style="font-size: 0.9rem"
                >
                  <ng-template pTemplate="input">
                    <input
                      (blur)="addAccreditationNo(rowData)"
                      [(ngModel)]="rowData.accreditationNumber"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    <div>
                      <img
                        style="margin-right: 0.5rem"
                        src="assets/edit.icon.svg"
                      />
                      {{ rowData.accreditationNumber || "Select" }}
                    </div>
                  </ng-template>
                </p-cellEditor>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" *ngIf="userList.length === 0">
            <tr>
              <td colspan="5">No Data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <!-- <app-button
        *ngIf="!edit"
        label="Save"
        [disabled]="formData.invalid"
        (clickEvent)="onSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
      <app-button
        *ngIf="edit"
        label="Update"
        [disabled]="formData.invalid"
        (clickEvent)="onUpdate()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      /> -->
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="inviteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '40vw' }"
  >
    <ng-template pTemplate="header">
      <h3>Invite User</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form style="margin-top: 1rem" class="dialog-form" [formGroup]="userData">
        <label class="input-label"> Invite to system </label>
        <div class="invite_user">
          <app-input
            [control]="userData.get('name')"
            placeholder="User Name"
            error="From"
          />
          <app-input
            [control]="userData.get('email')"
            placeholder="E-mail"
            error="To"
          />
          <app-button
            label="Invite"
            style="margin-top: 0.3rem"
            (clickEvent)="onInvite()"
            [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Close"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Aggregator ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Delete"
        (clickEvent)="onDeleteAggregator()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteUser"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to Deactive this User ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />

      <app-button
        label="Deactive"
        (clickEvent)="onDeleteUser()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>
