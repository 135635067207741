// src/presets/lara/inputgroup/index.ts
var inputgroup_default = {
  addon: {
    borderRadius: "{form.field.border.radius}",
    padding: "0.625rem 0.5rem",
    minWidth: "2.75rem"
  },
  colorScheme: {
    light: {
      addon: {
        background: "{surface.50}",
        borderColor: "{form.field.border.color}",
        color: "{text.muted.color}"
      }
    },
    dark: {
      addon: {
        background: "{surface.800}",
        borderColor: "{form.field.border.color}",
        color: "{text.muted.color}"
      }
    }
  }
};
export { inputgroup_default as default };
