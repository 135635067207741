import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class ProjectFieldsService {
  constructor(private api: ApiService) {}

  async fetchProjectFieldsData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get('api/project/fieldmaps'));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createProjectFieldsData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post('api/project/fieldmaps', payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
