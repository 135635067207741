import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class SchemeService {
  constructor(private api: ApiService) {}

  // Scheme Services
  async fetchSchemeData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get('api/scheme'));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createSchemeData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.post('api/scheme', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateSchemeData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.put('api/scheme', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteSchemeData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.delete(`api/scheme/${id}`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  // Activities Services
  async fetchActivityData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/scheme/${id}/activity`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createActivityData(payload: any, schemeId: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/scheme/${schemeId}/activity`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateActivityData(schemeId: number, payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/scheme/${schemeId}/activity`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteActivityData(id: number, schemeId: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.delete(`api/scheme/${schemeId}/activity/${id}`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // Field-Sets Services
  async fetchFieldSetData(schemeId: number, activityId: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/scheme/${schemeId}/activity/${activityId}/fieldset`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createFieldSetData(
    schemeId: number,
    activityId: number,
    payload: any
  ): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(
          `api/scheme/${schemeId}/activity/${activityId}/fieldset`,
          payload
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFieldSetData(
    schemeId: number,
    activityId: number,
    fieldId: any
  ): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.delete(
          `api/scheme/${schemeId}/activity/${activityId}/fieldset/${fieldId}`
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createFieldMapData(
    schemeId: number,
    activityId: number,
    fieldsetId: number,
    payload: any
  ): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(
          `api/scheme/${schemeId}/activity/${activityId}/fieldset/${fieldsetId}/fieldmap`,
          payload
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFieldMapData(
    schemeId: number,
    activityId: number,
    fieldsetId: number,
    payload: any
  ): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(
          `api/scheme/${schemeId}/activity/${activityId}/fieldset/${fieldsetId}/fieldmap`,
          payload
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchFieldMapData(
    schemeId: number,
    activityId: number,
    fieldsetId: number
  ): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(
          `api/scheme/${schemeId}/activity/${activityId}/fieldset/${fieldsetId}/fieldmap`
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
