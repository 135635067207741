// src/presets/lara/picklist/index.ts
var picklist_default = {
  root: {
    gap: "1.125rem"
  },
  controls: {
    gap: "0.5rem"
  }
};
export { picklist_default as default };
