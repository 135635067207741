export const environment = {
  production: false,
  baseApiUrl: window.location.origin,
  abnValidationUrl: "https://abr.business.gov.au/json/AbnDetails.aspx?callback=JSONP_CALLBACK&guid=135c2e34-f362-4fa8-9dc5-6730de83b85a&noCacheIE=1680516399638",
  authority: "https://login.microsoftonline.com/a7b7cdfc-fb82-4970-956e-413fd2274d48",
  redirectUrl: window.location.origin,
  clientId: "2dfcbbaf-9723-43a9-9ab3-ba4c82352cbe",
  scope: "openid profile api://91f27aa9-ab52-4b7e-9a76-73e182410b90/admin.api",
  uri: 'https://admin.themis.dev.dact.cloud/api'
};
