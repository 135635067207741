// src/presets/lara/inputotp/index.ts
var inputotp_default = {
  root: {
    gap: "0.5rem"
  },
  input: {
    width: "2.5rem",
    sm: {
      width: "2rem"
    },
    lg: {
      width: "3rem"
    }
  }
};
export { inputotp_default as default };
