import { Component } from '@angular/core';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { SharedModule } from '../../shared.module';
import { TableCols } from '../../types';
import { CATALOGUE_COLS } from '../../../constants';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CatalogueService } from './catalogue.services';
import { MessageService } from 'primeng/api';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrl: './catalogue.component.scss',
  providers: [MessageService],
  imports: [
    SearchFieldComponent,
    SharedModule,
    IconFieldModule,
    InputIconModule,
  ],
})
export class CatalogueComponent {
  cols: TableCols[] = CATALOGUE_COLS;
  CatalogueList: any[] = [];
  catalogueCount: number = 0;
  first = 0;
  pageNumber: number = 0;
  currentPageSize: number = 10;
  isLoading: boolean = true;
  skeletonRows = new Array(this.currentPageSize);
  searchSubject: Subject<string> = new Subject<string>();
  searchText: string = '';

  constructor(
    private service: CatalogueService,
    private messageService: MessageService
  ) {
    this.setupSearch();
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  async ngOnInit() {
    this.CatalogueList = await this.getCatalogueData(0, 10);
  }

  async getCatalogueData(pageNo: number, pageSize: number): Promise<any[]> {
    try {
      const res = await this.service.fetchCatalogueData(
        pageNo,
        pageSize,
        this.searchText
      );
      if (res) this.isLoading = false;
      this.catalogueCount = res.totalCount;
      return res.data;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  private setupSearch() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((value: string) => {
      this.searchText = value;
      this.pageNumber = 0;
      this.first = 0;
      this.handleSearch(this.pageNumber, this.currentPageSize, value);
    });
  }

  async handleSearch(
    pageNo: number,
    pageSize: number,
    val: string
  ): Promise<void> {
    try {
      if (val !== '') {
        const res = await this.service.fetchCatalogueData(
          pageNo,
          pageSize,
          val
        );
        if (res) {
          this.CatalogueList = res.data;
          this.catalogueCount = res.totalCount;
        }
      } else {
        this.CatalogueList = await this.getCatalogueData(pageNo, pageSize);
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async pageChange(event: any): Promise<void> {
    this.pageNumber = event.first / event.rows;
    this.first = event.first;
    if (this.searchText) {
      this.handleSearch(this.pageNumber, this.currentPageSize, this.searchText);
    } else {
      this.CatalogueList = await this.getCatalogueData(
        this.pageNumber,
        this.currentPageSize
      );
    }
  }

  async onPageSizeChange(event: any) {
    this.currentPageSize = event.value;
    if (this.searchText) {
      this.handleSearch(this.pageNumber, event.value, this.searchText);
    } else {
      this.CatalogueList = await this.getCatalogueData(
        this.pageNumber,
        event.value
      );
    }
  }
}
