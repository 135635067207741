import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { InputTextModule } from 'primeng/inputtext';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-disabled-input',
  imports: [SharedModule, InputTextModule],
  templateUrl: './disabled-input.component.html',
  styleUrl: './disabled-input.component.scss',
})
export class DisabledInputComponent {
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() control!: any;
}
