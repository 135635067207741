<div>
  <form [formGroup]="formData">
    <div class="constant-container">
      <app-dropdown
        placeholder="Zone"
        [control]="formData.get('zone')"
        [options]="zoneList"
        optionLabel="name"
        error="Zone"
      />
      <app-calendar [control]="formData.get('date')" error="Form Date" />
      <div
        *ngFor="let range of valuesControls; let i = index"
        class="value-picklist"
      >
        <div class="flex">
          <app-input
            [control]="range.get('key')"
            placeholder="key"
            error="key"
            style="width: 100%"
          />
          <app-input
            [control]="range.get('value')"
            placeholder="value"
            error="Value"
            style="width: 100%"
          />
          <span
            class="pi pi-times"
            style="color: #e71d36; margin-left: 1rem; margin-top: 1rem"
            (click)="removeKeyValue(i)"
          ></span>
        </div>
      </div>
      <app-button
        label="Add"
        (clickEvent)="addKeyValue()"
        style="margin-top: 0.3rem"
        [disabled]="addButtonDisabled"
      />
    </div>
    <app-button
      *ngIf="!editValue"
      label="Add Value"
      (clickEvent)="handleAddNew()"
      class="picklist-button"
      [disabled]="formData.invalid"
    />
    <app-button
      *ngIf="editValue"
      label="Edit Value"
      (clickEvent)="handleUpdate()"
      class="picklist-button"
      [disabled]="formData.invalid"
    />
  </form>

  <p-table [columns]="Cols" [value]="picklistValue">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
      let-i="rowIndex"
    >
      <tr *ngFor="let data of rowData.values; let isFirstRow = first">
        <td *ngIf="isFirstRow" [attr.rowspan]="rowData.values.length">
          {{ rowData?.zone?.name ?? "All Zone" }}
        </td>
        <td *ngIf="isFirstRow" [attr.rowspan]="rowData.values.length">
          {{ rowData.date | date : "dd/MM/yyyy" }}
        </td>
        <td>{{ data.key }}</td>
        <td>{{ data.value }}</td>
        <td
          style="width: 5%; text-align: end"
          *ngIf="isFirstRow"
          (click)="op.toggle($event)"
          [attr.rowspan]="rowData.values.length"
        >
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-popover #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData, i)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                (click)="handleDelete(i)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-popover>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
