import { Component } from '@angular/core';
import { ButtonComponent } from '../../Components/button/button.component';
import { MsalServiceData } from './msal.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
  imports: [ButtonComponent],
})
export class LandingPageComponent {
  constructor(private msalService: MsalServiceData) {}

  async ngOnInit(): Promise<any> {
    await this.msalService.createInstance();
    await this.msalService.initialize();
  }

  async login() {
    this.msalService.loginRedirect();
  }
}
