<p-toast />
<div class="page-container simulate-job">
  <form class="main-container" [formGroup]="formData">
    <app-number-input
      [control]="formData.get('postalCode')"
      inputId="withoutgrouping"
      mode="decimal"
      [useGrouping]="false"
      label="Postal Code"
    />
    <app-dropdown
      label="Device Type"
      [control]="formData.get('deviceType')"
      [options]="deviceTypeList"
      optionLabel="label"
      optionValue="label"
      placeholder="Select"
    />
    <app-dropdown
      label="Dwelling Type"
      [control]="formData.get('dwellingType')"
      [options]="dwellingTypeList"
      optionLabel="label"
      optionValue="label"
      placeholder="Select"
    />
    <app-calendar label="Date" [control]="formData.get('date')" />
  </form>

  <app-button label="Simulate" (clickEvent)="handleSimulate()" />

  <!-- SIMULATE DIALOG -->
  <p-dialog
    header="Header"
    [(visible)]="simulateDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header">
      <h3>Simulate</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <p-table
        [columns]="cols"
        dataKey="id"
        [value]="simulateList"
        [(selection)]="selectedRows"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 4rem"></th>
            <th *ngFor="let col of columns">{{ col.header }}</th>
          </tr>
          <ng-container *ngIf="isLoading">
            <tr *ngFor="let _ of skeletonRows">
              <th class="skeleton"><p-skeleton></p-skeleton></th>
              <th class="skeleton" *ngFor="let col of columns">
                <p-skeleton></p-skeleton>
              </th>
            </tr>
          </ng-container>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr *ngFor="let activities of rowData.schemeActivities">
            <td>
              <p-tableRadioButton [value]="activities" />
            </td>
            <td>{{ rowData.name }}</td>
            <td>{{ activities.name }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" *ngIf="simulateList.length === 0">
          <tr *ngIf="!isLoading">
            <td colspan="3">No Data found.</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>

    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeSimulateDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button label="Calculate" (clickEvent)="handleCalculation()" />
    </ng-template>
  </p-dialog>

  <!-- CALCULATION DIALOG -->
  <p-dialog
    header="Header"
    [(visible)]="calculationDialog"
    [modal]="true"
    [closable]="false"
  >
    <ng-template pTemplate="header">
      <h3>Calculation</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form [formGroup]="deviceFrom">
        <div class="output-container">
          <app-number-input
            [control]="deviceFrom.get('CatelogueEntryId')"
            inputId="withoutgrouping"
            mode="decimal"
            [useGrouping]="false"
            label="Device Id"
          />
        </div>
      </form>
      <form [formGroup]="calculationForm">
        <div class="calculation-container">
          <div *ngFor="let fields of assessmentFields">
            <ng-container [ngSwitch]="fields.fieldType">
              <app-input
                *ngSwitchCase="'DE_Numeric'"
                [control]="calculationForm.get(fields.code)"
                [label]="fields.code"
              />

              <div *ngSwitchCase="'Constant'">
                <label class="input-label">{{ fields.code }}</label>
                <p-inputNumber
                  [disabled]="true"
                  [formControlName]="fields.code"
                />
              </div>
              <app-dropdown
                *ngSwitchCase="'DE_Picklist'"
                [options]="fields.optionValue"
                [label]="fields.code"
                placeholder="Select"
                optionLabel="key"
                optionValue="value"
                [control]="calculationForm.get(fields.code)"
              />
              <div *ngSwitchCase="'Boolean'">
                <label class="input-label">{{ fields.code }}</label>
                <div style="display: flex; gap: 2rem; padding-top: 0.7rem">
                  <div *ngFor="let data of Boolean">
                    <p-radioButton
                      [inputId]="data.key"
                      [value]="data.key"
                      [formControlName]="fields.code"
                    />
                    <label [for]="data.key" class="ml-2">
                      {{ data.name }}
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeCalculationDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Calculate"
        (clickEvent)="onCalculate()"
        [disabled]="calculationForm.invalid || deviceFrom.invalid"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="outputDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '25vw' }"
  >
    <ng-template pTemplate="header">
      <h3>Output</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <p-table [columns]="cols" [value]="certificateList">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Certificate</th>
            <th>Price</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>{{ rowData.label }}</td>
            <td>{{ rowData.value }}</td>
          </tr>
        </ng-template>

        <ng-template
          pTemplate="emptymessage"
          *ngIf="certificateList.length === 0"
        >
          <tr *ngIf="!isLoading">
            <td colspan="3">No Data found.</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>

    <ng-template pTemplate="footer">
      <app-button
        label="Close"
        (clickEvent)="closeOutputDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
    </ng-template>
  </p-dialog>
</div>
