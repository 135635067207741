import { Injectable } from '@angular/core';
import { PublicClientApplication } from '@azure/msal-browser';
import { ConfigService } from '../../Services/config-service';
import { Router } from '@angular/router';
import ROUTES from '../../../routes.const';
import { TOKEN } from '../../../constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MsalServiceData {
  private msalInstance!: PublicClientApplication;
  // tenantId!: string;

  constructor(private configService: ConfigService, private router: Router) {}

  async createInstance() {
    // const res = await this.configService.getMsalConfig().toPromise();
    // const { adTenantId, clientId } = res;
    // this.tenantId = adTenantId;

    // Initialize MSAL instance here
    this.msalInstance = new PublicClientApplication({
      auth: {
        clientId: environment.clientId,
        authority: environment.authority,
        redirectUri: window.origin,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    });
  }

  getMsalInstance(): PublicClientApplication {
    return this.msalInstance;
  }

  async initialize(): Promise<any> {
    await this.msalInstance.initialize();
  }

  async loginRedirect() {
    try {
      await this.msalInstance
        .acquireTokenPopup({
          authority: environment.authority,
          redirectUri: window.origin,
          scopes: [environment.scope],
        })
        .then((c) => {
          localStorage.setItem(TOKEN, c.idToken);
          const token = localStorage.getItem(TOKEN);
          if (token) {
            this.router.navigate([ROUTES.DASHBOARD]);
          }
        });
    } catch (error) {
      console.error('Login redirect error:', error);
    }
  }

  logout() {
    this.msalInstance.logoutPopup().then((c) => {
      this.router.navigate([ROUTES.LOGIN]);
    });
  }

  async handleRedirectCallback(): Promise<void> {
    try {
      const fragment = window.location.hash.substring(1); // Get fragment without '#'
      const urlParams = new URLSearchParams(fragment);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      if (code) {
        const response = await this.msalInstance.acquireTokenByCode({
          code,
          redirectUri: window.origin,
          scopes: [environment.scope], // Adjust scopes based on your application's needs
        });

        // Handle successful token acquisition
        console.log('Access token acquired:', response.accessToken);
        // Store the access token securely (e.g., in localStorage or sessionStorage)

        // Optional: Redirect user away from the callback URL to a secure route
        this.router.navigate(['/secure-route']);
      } else {
        console.error('No authorization code found in URL');
        // Handle error scenario, such as redirect to login page or display error message
      }
    } catch (error) {
      console.error('Error acquiring token by code:', error);
      // Handle error scenario, such as redirect to login page or display error message
    }
  }
}
