import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export function postalCodeRangeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const postCodesArray = control as FormArray;
    const postCodes = postCodesArray.value;

    if (postCodes && postCodes.length > 1) {
      for (let i = 0; i < postCodes.length - 1; i++) {
        const currentFrom = postCodes[i].from;
        const currentTo = postCodes[i].to;
        for (let j = i + 1; j < postCodes.length; j++) {
          const nextFrom = postCodes[j].from;
          const nextTo = postCodes[j].to;
          if (
            (nextFrom >= currentFrom && nextFrom <= currentTo) ||
            (nextTo >= currentFrom && nextTo <= currentTo)
          ) {
            return { overlappingCodes: true };
          }
        }
      }
    }
    return null;
  };
}

export function trimFormData(formData: any): any {
  const trimmedData: any = {};
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const value = formData[key];
      trimmedData[key] = typeof value === 'string' ? value.trim() : value;
    }
  }
  return trimmedData;
}
