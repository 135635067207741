import { Component } from '@angular/core';
import { SearchFieldComponent } from '../../../Components/searchField/search-text.component';
import { ButtonComponent } from '../../../Components/button/button.component';
import { HAND_OUT_DOCUMENTS_COLS } from '../../../../constants';
import { InputComponent } from '../../../Components/input/input.component';
import { SharedModule } from '../../../shared.module';
import { TableCols, DocumentType } from '../../../types';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DocumentsService } from './handout-document.services';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-hand-out-documents',
  templateUrl: './hand-out-documents.component.html',
  styleUrl: './hand-out-documents.component.scss',
  providers: [MessageService],
  imports: [
    SearchFieldComponent,
    ButtonComponent,
    InputComponent,
    SharedModule,
    ReactiveFormsModule,
  ],
})
export class HandOutDocumentsComponent {
  cols: TableCols[] = HAND_OUT_DOCUMENTS_COLS;
  HandOutDocumentList: DocumentType[] = [];
  onHover: number = 0;
  selectedRows: DocumentType[] = [];
  display: boolean = false;
  edit: boolean = false;
  selectedFileName: string | null = null;
  isLoading: boolean = true;
  formData!: FormGroup;
  skeletonRows = new Array(10);
  deleteDialog: boolean = false;
  RowData!: DocumentType;
  onSaveLoad: boolean = false;
  searchText: string = '';
  documentDataClone: DocumentType[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private service: DocumentsService,
    private messageService: MessageService
  ) {
    this.formData = this.formBuilder.group({
      title: ['', Validators.required],
      version: ['', Validators.required],
      document: [''],
    });
  }

  async ngOnInit() {
    this.HandOutDocumentList = await this.documentsData();
  }

  private async documentsData(): Promise<DocumentType[]> {
    try {
      const data = await this.service.fetchDocumentsData();
      this.documentDataClone = data;
      if (data) this.isLoading = false;
      return data;
    } catch (error: any) {
      this.errorToast(error.message);
      this.isLoading = false;
      return [];
    }
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.searchText = searchVal.trim();
    this.HandOutDocumentList = this.documentDataClone.filter(
      (item) =>
        item.title.toLowerCase().includes(searchVal.trim().toLowerCase()) ||
        item.version.toLowerCase().includes(searchVal.trim().toLowerCase())
    );
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  handleDownload(): void {}

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  handleNewUpload(): void {
    this.formData.reset();
    this.display = true;
    this.selectedFileName = null;
    this.edit = false;
  }

  handleEdit(rowData: DocumentType): void {
    this.formData.patchValue({
      title: rowData.title || '',
      version: rowData.version || '',
      document: '',
    });
    this.selectedFileName = null;
    this.display = true;
    this.edit = true;
    this.RowData = rowData;
  }

  closeDialog(): void {
    this.display = false;
    this.edit = false;
    this.deleteDialog = false;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    const { title, version, document } = this.formData.value;
    try {
      const payload = {
        Title: title,
        Version: version,
        File: document,
        Description: title,
      };
      const res = await this.service.createDocumentsData(payload);
      if (res) {
        this.formData.reset();
        this.display = false;
        this.onSaveLoad = false;
        this.HandOutDocumentList = await this.documentsData();
        this.handleSearch(this.searchText);
        this.successToast('Hand Out Document Created Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    const { title, version, document } = this.formData.value;
    try {
      const payload = {
        Title: title,
        Version: version,
        File: document,
        Description: title,
        Id: this.RowData.id,
      };
      const res = await this.service.editDocumentsData(payload);
      if (res) {
        this.formData.reset();
        this.display = false;
        this.onSaveLoad = false;
        this.HandOutDocumentList = await this.documentsData();
        this.handleSearch(this.searchText);
        this.successToast('Hand Out Document Updated Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
      this.onSaveLoad = false;
    }
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0] as File;
    this.formData.patchValue({ document: file });
    this.selectedFileName = event.target.files[0].name || null;
  }

  removeFile(): void {
    this.formData.patchValue({ document: '' });
    this.selectedFileName = null;
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.formData.patchValue({ document: files[0] });
      this.selectedFileName = files[0].name;
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
  }

  handleDelete(rowData: DocumentType): void {
    this.RowData = rowData;
    this.deleteDialog = true;
  }

  async onDeleteDocument(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.service.deleteDocumentsData(this.RowData.id);
      if (res) {
        this.deleteDialog = false;
        this.onSaveLoad = false;
        this.HandOutDocumentList = await this.documentsData();
        this.handleSearch(this.searchText);
        this.successToast('Hand Out Document Deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async downloadFile(rowData: DocumentType): Promise<void> {
    await this.service.getDocumentFile(rowData.id, rowData.title);
  }
}
