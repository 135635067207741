// src/presets/lara/virtualscroller/index.ts
var virtualscroller_default = {
  loader: {
    mask: {
      background: "{content.background}",
      color: "{text.muted.color}"
    },
    icon: {
      size: "2rem"
    }
  }
};
export { virtualscroller_default as default };
