export const MANAGE_ZONE = [
  {
    id: 1,
    zone_name: 'Zone 1',
    state: 'TAS',
    postal_code: [
      {
        from_date: new Date('2023-06-02T18:30:00.000Z'),
        code: [
          { from: '3033', to: '3044' },
          { from: '4044', to: '4055' },
        ],
      },
      {
        from_date: new Date('2024-04-11T18:30:00.000Z'),
        code: [
          { from: '8033', to: '8044' },
          { from: '9044', to: '9055' },
        ],
      },
    ],
  },
  {
    id: 2,
    zone_name: 'Zone 2',
    state: 'NSW',
    postal_code: [
      {
        from_date: new Date('2024-12-21T18:30:00.000Z'),
        code: [{ from: '8130', to: '8133' }],
      },
      {
        from_date: new Date('2024-03-31T18:30:00.000Z'),
        code: [{ from: '1130', to: '1133' }],
      },
      {
        from_date: new Date('2024-04-21T18:30:00.000Z'),
        code: [{ from: '1130', to: '1133' }],
      },
    ],
  },
];

export const HAND_OUT_DOCUMENTS = [
  {
    id: 1,
    title: 'IPRE Nomination Form',
    version: 'v3.26',
    document: '',
  },
  {
    id: 2,
    title: 'IPRE Tech Check',
    version: 'v3.16',
    document: '',
  },
];

export const MANAGE_FIELD = [
  {
    id: 1,
    code: 'CD01',
    name: 'WH-New-Unit-Wide',
    type: 'Photo',
    description: 'description 1',
    alter_field: ['Cust-Meter', 'Cust-NMI'],
  },
  {
    id: 2,
    code: 'CD02',
    name: 'Cust-NMI',
    type: 'Constant',
    description: 'description 2',
    alter_field: ['WH-New-Unit-Wide'],
    ConstantValue: [
      {
        zone: 'Zone 1',
        from_date: new Date('2024-04-21T18:30:00.000Z'),
        values: '01.333',
      },
      {
        zone: 'Zone 2',
        from_date: new Date('2024-04-02T18:30:00.000Z'),
        values: '3.555',
      },
    ],
  },
  {
    id: 3,
    code: 'CD03',
    name: 'Cust-Meter',
    type: 'DE-Picklist',
    description: 'description 3',
    alter_field: ['WH-New-Unit-Wide', 'Cust-NMI'],
    picklist_value: [
      {
        zone: 'Zone 1',
        date: new Date('2024-04-23T18:30:00.000Z'),
        values: [
          { key: 'High', value: '90' },
          { key: 'Low', value: '23' },
          { key: 'Medium', value: '50' },
        ],
      },
      {
        zone: 'Zone 2',
        date: new Date('2024-04-23T18:30:00.000Z'),
        values: [
          { key: 'High', value: '120' },
          { key: 'Low', value: '23' },
        ],
      },
    ],
  },
];

export const SIMULATE_LIST = [
  { id: 1, scheme: 'TCS', activities: 'Replace Gas fired steam boiler' },
  { id: 2, scheme: 'STC', activities: 'Add HW' },
  { id: 3, scheme: 'ECS', activities: 'Replace HW' },
];

export const ACTIVITES_LIST = [
  {
    name: 'Replace Gas fired steam boiler',
    device_type: ['H/W', 'Steam boiler'],
    dwelling_type: 'Residential',
    co_payment: 12,
    selectedCategory: 'new',
  },
];

export const AGGREGATOR_LIST = [
  {
    id: 1,
    aggregator: '4 Eva Energy',
    logo: './assets/mock1.logo.svg',
    status: 'Offline',
    ABN: '#12344',
    area: 'India',
    activities: ['STC H/W Replace', 'PRC H/W Replace', 'STC H/W New'],
    invited_user: [
      {
        id: 1,
        name: 'Bob nillo',
        email: 'bobnillo@4eva.com',
        status: 'Active',
      },
      {
        id: 2,
        name: 'John Smith',
        email: 'johnsmith@4eva.com',
        status: 'Invited',
      },
    ],
  },
  {
    id: 2,
    aggregator: 'Creditx',
    logo: './assets/mock2.logo.svg',
    status: 'Online',
    ABN: '#45678',
    area: 'India',
    activities: ['PRC H/W Replace', 'STC H/W New'],
    invited_user: [
      {
        id: 1,
        name: 'Bob nillo',
        email: 'bobnillo@4eva.com',
        status: 'Active',
      },
    ],
  },
  {
    id: 3,
    aggregator: 'Demand MGR',
    logo: './assets/mock.logo.svg',
    status: 'Online',
    ABN: '#87578',
    area: 'India',
    activities: ['PRC H/W Replace'],
    invited_user: [],
  },
];

export const ACTIVITES = [
  { id: 1, activities: 'STC H/W Replace' },
  { id: 2, activities: 'PRC H/W Replace' },
  { id: 3, activities: 'STC H/W New' },
];

export const DASHBOARD = [
  {
    name: 'Eva Energy',
    new: 2,
    installation: 7,
    audit: 3,
    complete: 4,
    assessments: 2,
  },
  {
    name: 'Creditex',
    new: 5,
    installation: 3,
    audit: 7,
    complete: 5,
    assessments: 8,
  },
  {
    name: 'Installer 3',
    new: 4,
    installation: 5,
    audit: 8,
    complete: 9,
    assessments: 7,
  },
  {
    name: 'Demand MGR',
    new: 6,
    installation: 6,
    audit: 9,
    complete: 6,
    assessments: 5,
  },
];

export const CATALOGUE_LIST = [
  {
    id: 1,
    model: 'Model Name',
    manufacture: 'Manufacture Company',
    category: 'Category',
  },
  {
    id: 2,
    model: 'Name',
    manufacture: 'Company',
    category: 'Category',
  },
];

export const PICKLIST_DATA = [
  { key: 'High' },
  { key: 'Medium' },
  { key: 'Low' },
];

export const SCHEME_LIST = [
  {
    name: 'PRC',
    regulator: 'IPART',
    state: ['VIC', 'NSW'],
    activities: [
      {
        device_type: ['H/W', 'Steam boiler'],
        dwelling_type: 'Residential',
        name: 'STC H/W Replace',
        history: [
          {
            assessmentList: [
              { id: 1, activities: 'WH-New-Unit-Wide' },
              { id: 2, activities: 'Cust-Meter' },
              { id: 3, activities: 'Cust-NMI' },
            ],
            installationList: [
              { id: 1, activities: 'Cust-NMI' },
              { id: 2, activities: 'H/W Replace' },
              { id: 3, activities: 'H/W New' },
            ],
            valid_from: new Date('2024-04-23T18:30:00.000Z'),
          },
          {
            assessmentList: [
              { id: 1, activities: 'STC H/W Replace' },
              { id: 2, activities: 'PRC H/W Replace' },
            ],
            installationList: [{ id: 1, activities: 'STC H/W Replace' }],
            valid_from: new Date('2024-12-06T18:30:00.000Z'),
          },
        ],
      },
      {
        name: 'Replace Gas fired steam boiler',
        device_type: ['H/W', 'Steam boiler'],
        dwelling_type: 'Residential',
        history: [
          {
            assessmentList: [{ id: 1, activities: 'STC H/W Replace' }],
            installationList: [{ id: 1, activities: 'STC H/W Replace' }],
            valid_from: new Date('2024-04-23T18:30:00.000Z'),
          },
        ],
      },
    ],
  },
  {
    name: 'STC',
    regulator: 'IPART',
    state: ['VIC', 'NSW', 'NT'],
    activities: [
      {
        name: 'Replace Gas fired steam boiler',
        device_type: ['H/W', 'Steam boiler'],
        dwelling_type: 'Residential',
        history: [],
      },
    ],
  },
  {
    name: 'VEC',
    regulator: 'IPART',
    state: ['SA', 'WA'],
    activities: [
      {
        name: 'Replace Gas fired steam boiler',
        device_type: ['H/W', 'Steam boiler'],
        dwelling_type: 'Residential',
        history: [],
      },
    ],
  },
];

export const CERTIFICATE_DATA = [
  {
    name: 'ESC',
    averageCertificateCount: 10,
    nameOfMonth: 'Jan',
  },
  {
    name: 'PRC',
    averageCertificateCount: 11.5,
    nameOfMonth: 'May',
  },
  {
    name: 'VEEC',
    averageCertificateCount: 30,
    nameOfMonth: 'Dec',
  },
  {
    name: 'STC',
    averageCertificateCount: 40,
    nameOfMonth: 'March',
  },
  {
    name: 'LCG',
    averageCertificateCount: 25,
    nameOfMonth: 'Nov',
  },
  {
    name: 'ACCU',
    averageCertificateCount: 29,
    nameOfMonth: 'Oct',
  },
];
