<div class="sidebar-container">
  <div
    *ngFor="let item of sideBar"
    [ngClass]="{ 'menu-items': true, active: activeTab === item.label }"
  >
    <div (click)="navigateTo(item.link, item.label)" class="label-container">
      <img [src]="item.icon" width="20px" height="20px" alt="Icon" />
      <div
        [ngClass]="{
          'label-wrapper': true,
          'active-text': activeTab === item.label
        }"
      >
        {{ item.label }}
      </div>
      <div
        *ngIf="item.subMenu"
        class="expand-icon"
        (click)="toggleSubMenu(item)"
      >
        <img src="assets/expansionIcon.svg" alt="Expand Icon" />
      </div>
    </div>
    <div *ngIf="subTabVisible && item.subMenu" class="sub-menu">
      <div
        *ngFor="let subItem of item.subMenu"
        [ngClass]="{
          'submenu-wrapper': true,
          active: activeSubTab === subItem.label
        }"
      >
        <div
          (click)="navigateToSubmenu(subItem.label, subItem.link)"
          [ngClass]="{
            'label-wrapper': true,
            'active-text': activeSubTab === subItem.label
          }"
        >
          {{ subItem.label }}
        </div>
      </div>
    </div>
  </div>
</div>
