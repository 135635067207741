// src/presets/lara/progressspinner/index.ts
var progressspinner_default = {
  colorScheme: {
    light: {
      root: {
        "color.1": "{pink.500}",
        "color.2": "{sky.500}",
        "color.3": "{emerald.500}",
        "color.4": "{amber.500}"
      }
    },
    dark: {
      root: {
        "color.1": "{pink.400}",
        "color.2": "{sky.400}",
        "color.3": "{emerald.400}",
        "color.4": "{amber.400}"
      }
    }
  }
};
export { progressspinner_default as default };
