<div class="page-container catalogue-container">
  <p-toast />
  <div class="header-container">
    <div class="header">Product Catalogue</div>
    <app-search-text
      (searchEvent)="searchSubject.next($event)"
      class="header"
    />
  </div>

  <p-table
    [columns]="cols"
    [value]="CatalogueList"
    [paginator]="true"
    [lazy]="true"
    (onPage)="pageChange($event)"
    dataKey="id"
    [rows]="currentPageSize"
    [first]="first"
    [totalRecords]="catalogueCount"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="paginatorright">
      <p-select
        [options]="[
          { label: '10', value: 10 },
          { label: '20', value: 20 },
          { label: '50', value: 50 }
        ]"
        [(ngModel)]="currentPageSize"
        (onChange)="onPageSizeChange($event)"
      >
      </p-select>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>{{ rowData.model }}</td>
        <td>{{ rowData.manufacturer }}</td>
        <td>{{ rowData.deviceType }}</td>
        <!-- <td>
          <img src="assets/eye-t.svg" />
        </td> -->
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="CatalogueList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
