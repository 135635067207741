import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class FieldService {
  constructor(private api: ApiService) {}

  async fetchFieldData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get('api/field'));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createFieldData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.post('api/field', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editFieldData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.put('api/field', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFieldData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.delete(`api/field/${id}`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchActivityFields(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/field/purpose?fieldPurpose=activity`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchFieldStages(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/master/field-stages`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchFieldPurpose(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/master/field-purposes`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
