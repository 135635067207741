import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class CertificateService {
  constructor(private api: ApiService) {}

  async fetchCertificateData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get(`api/Certificates`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createCertificateData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post('api/Certificates', payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
