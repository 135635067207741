<div class="number-input">
  <label class="input-label">
    {{ label }}
    <span *ngIf="required" class="required-label">*</span>
  </label>
  <p-inputNumber
    [formControl]="control"
    [inputId]="inputId"
    [mode]="mode"
    [placeholder]="placeholder"
    [useGrouping]="useGrouping"
    [minFractionDigits]="minFractionDigits"
    [maxFractionDigits]="maxFractionDigits"
    [disabled]="disabled"
    [prefix]="prefix"
  />
  <div *ngIf="control.invalid && control.touched" class="error-message">
    {{ label ? label : error }} is required.
  </div>
</div>
