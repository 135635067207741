import { definePreset } from '@primeng/themes';
import Lara from '@primeng/themes/lara';

export const MyPreset = definePreset(Lara, {
  semantic: {
    primary: {
      50: '#f5f7ff',
      100: '#e0e5ff',
      200: '#b3c1ff',
      300: '#809aff',
      400: '#4d73ff',
      500: '#235aff',
      600: '#1f50e6',
      700: '#1a45cc',
      800: '#163ab3',
      900: '#10288a',
      950: '#0d1f6e',
    },
  },
});
