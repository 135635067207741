import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class InstallerService {
  constructor(private api: ApiService) {}

  async fetchInstallerData(
    pageNo: number,
    pageSize: number,
    search: string
  ): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(
          `api/installer/search?pageNumber=${pageNo + 1}&pageSize=${pageSize}`,
          { searchText: search }
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createInstallerData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post('api/installer', payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateInstallerData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.put('api/installer', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }
}
