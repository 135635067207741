import { Component } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import {
  DropDownType,
  SchemeActivityType,
  SchemeType,
  TableCols,
} from '../../../types';
import {
  ACTIVITIES,
  DEVICE_TYPE,
  DWELLING_TYPE,
  LICENCE_LIST,
} from '../../../../constants';
import { SchemeService } from '../scheme.services';
import { MessageService } from 'primeng/api';
import { SearchFieldComponent } from '../../../Components/searchField/search-text.component';
import { ButtonComponent } from '../../../Components/button/button.component';
import { InputComponent } from '../../../Components/input/input.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { NumberInputComponent } from '../../../Components/number-input/number-input.component';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-activities',
  providers: [MessageService],
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.scss',
  imports: [
    SharedModule,
    SearchFieldComponent,
    ButtonComponent,
    InputComponent,
    DropdownComponent,
    NumberInputComponent,
  ],
})
export class ActivitiesComponent {
  cols: TableCols[] = ACTIVITIES;
  activitiesList: SchemeActivityType[] = [];
  schemeList!: SchemeType;
  onHover: number = 0;
  isLoading: boolean = true;
  display: boolean = false;
  editActivity: boolean = false;
  skeletonRows = new Array(10);
  formData!: FormGroup;
  onSaveLoad: boolean = false;
  deviceList: DropDownType[] = DEVICE_TYPE;
  dwellingList: DropDownType[] = DWELLING_TYPE;
  rowData!: SchemeActivityType;
  rowId: number = 0;
  deleteActivtyDialog: boolean = false;
  activitiesDataClone: SchemeActivityType[] = [];
  categories: DropDownType[] = [
    { label: 'Replace', value: 'replace' },
    { label: 'New', value: 'new' },
  ];
  licenceList: DropDownType[] = LICENCE_LIST;

  constructor(
    private service: SchemeService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.initialFormValue();
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  nagivateToFields(rowData: SchemeActivityType): void {
    const { id } = rowData;
    const params: NavigationExtras = {
      state: { activity: rowData, scheme: this.schemeList },
    };
    this.router.navigate(
      [`scheme/${this.schemeList.id}/activity/${id}/field-set`],
      params
    );
  }

  async ngOnInit(): Promise<void> {
    this.schemeList = history.state.rowData;
    this.activitiesList = await this.getActivitiesData();
  }

  initialFormValue(): void {
    this.formData = this.formBuilder.group({
      code: ['', Validators.required],
      registrationCode: [''],
      name: ['', Validators.required],
      isReplace: [''],
      deviceType: ['', Validators.required],
      dwellingType: [null],
      minimumCoPayment: [0, Validators.required],
      calculationMethod: ['', Validators.required],
      // licence: [''],
    });
  }

  async getActivitiesData(): Promise<SchemeActivityType[]> {
    try {
      const res = await this.service.fetchActivityData(this.schemeList.id);
      const activities = res.map((data: SchemeActivityType) => ({
        ...data,
        // licence: data.licence.split(','),
      }));
      this.activitiesDataClone = activities;
      if (res) this.isLoading = false;
      return activities;
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  navigateBack(): void {
    window.history.back();
  }

  closeDialog(): void {
    this.display = false;
    this.deleteActivtyDialog = false;
  }

  handleAddNew(): void {
    this.display = true;
    this.editActivity = false;
    this.initialFormValue();
  }

  async handleSearch(search: string): Promise<void> {
    const searchVal = search.trim();
    this.activitiesList = this.activitiesDataClone.filter(
      (item) =>
        item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.deviceType.toLowerCase().includes(searchVal.toLowerCase()) ||
        item?.dwellingType?.toLowerCase().includes(searchVal.toLowerCase()) ||
        item?.registrationCode?.toLowerCase().includes(searchVal.toLowerCase())
    );
  }

  handleEdit(rowData: SchemeActivityType): void {
    this.display = true;
    this.editActivity = true;
    this.rowData = rowData;
    this.formData.patchValue({
      ...rowData,
      isReplace: rowData.isReplace ? this.categories[0] : this.categories[1],
    });
  }

  async handleDelete(rowData: SchemeActivityType): Promise<void> {
    this.deleteActivtyDialog = true;
    this.rowId = rowData.id;
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        id: this.rowData.id,
        ...this.formData.value,
        // licence: this.formData.value.licence.join(),
        isReplace:
          this.formData.value.isReplace.label === 'Replace' ? true : false,
      };
      const res = await this.service.updateActivityData(
        this.schemeList.id,
        payload
      );
      if (res) {
        this.onSaveLoad = false;
        this.display = false;
        this.successToast('Activity Updated Successfully');
        this.activitiesList = await this.getActivitiesData();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
        // licence: this.formData.value.licence.join(),
        isReplace:
          this.formData.value.isReplace.label === 'Replace' ? true : false,
      };
      const res = await this.service.createActivityData(
        payload,
        this.schemeList.id
      );
      if (res) {
        this.onSaveLoad = false;
        this.display = false;
        this.successToast('Activity Created Successfully');
        this.activitiesList = await this.getActivitiesData();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onDeleteActivity(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.service.deleteActivityData(
        this.rowId,
        this.schemeList.id
      );
      if (res) {
        this.onSaveLoad = false;
        this.deleteActivtyDialog = false;
        this.activitiesList = await this.getActivitiesData();
        this.successToast('Activity Deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }
}
