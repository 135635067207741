// src/presets/lara/index.ts
import accordion from "@primeng/themes/lara/accordion";
import autocomplete from "@primeng/themes/lara/autocomplete";
import avatar from "@primeng/themes/lara/avatar";
import badge from "@primeng/themes/lara/badge";
import base from "@primeng/themes/lara/base";
import blockui from "@primeng/themes/lara/blockui";
import breadcrumb from "@primeng/themes/lara/breadcrumb";
import button from "@primeng/themes/lara/button";
import card from "@primeng/themes/lara/card";
import carousel from "@primeng/themes/lara/carousel";
import cascadeselect from "@primeng/themes/lara/cascadeselect";
import checkbox from "@primeng/themes/lara/checkbox";
import chip from "@primeng/themes/lara/chip";
import colorpicker from "@primeng/themes/lara/colorpicker";
import confirmdialog from "@primeng/themes/lara/confirmdialog";
import confirmpopup from "@primeng/themes/lara/confirmpopup";
import contextmenu from "@primeng/themes/lara/contextmenu";
import datatable from "@primeng/themes/lara/datatable";
import dataview from "@primeng/themes/lara/dataview";
import datepicker from "@primeng/themes/lara/datepicker";
import dialog from "@primeng/themes/lara/dialog";
import divider from "@primeng/themes/lara/divider";
import dock from "@primeng/themes/lara/dock";
import drawer from "@primeng/themes/lara/drawer";
import editor from "@primeng/themes/lara/editor";
import fieldset from "@primeng/themes/lara/fieldset";
import fileupload from "@primeng/themes/lara/fileupload";
import floatlabel from "@primeng/themes/lara/floatlabel";
import galleria from "@primeng/themes/lara/galleria";
import iconfield from "@primeng/themes/lara/iconfield";
import iftalabel from "@primeng/themes/lara/iftalabel";
import image from "@primeng/themes/lara/image";
import imagecompare from "@primeng/themes/lara/imagecompare";
import inlinemessage from "@primeng/themes/lara/inlinemessage";
import inplace from "@primeng/themes/lara/inplace";
import inputchips from "@primeng/themes/lara/inputchips";
import inputgroup from "@primeng/themes/lara/inputgroup";
import inputnumber from "@primeng/themes/lara/inputnumber";
import inputotp from "@primeng/themes/lara/inputotp";
import inputtext from "@primeng/themes/lara/inputtext";
import knob from "@primeng/themes/lara/knob";
import listbox from "@primeng/themes/lara/listbox";
import megamenu from "@primeng/themes/lara/megamenu";
import menu from "@primeng/themes/lara/menu";
import menubar from "@primeng/themes/lara/menubar";
import message from "@primeng/themes/lara/message";
import metergroup from "@primeng/themes/lara/metergroup";
import multiselect from "@primeng/themes/lara/multiselect";
import orderlist from "@primeng/themes/lara/orderlist";
import organizationchart from "@primeng/themes/lara/organizationchart";
import overlaybadge from "@primeng/themes/lara/overlaybadge";
import paginator from "@primeng/themes/lara/paginator";
import panel from "@primeng/themes/lara/panel";
import panelmenu from "@primeng/themes/lara/panelmenu";
import password from "@primeng/themes/lara/password";
import picklist from "@primeng/themes/lara/picklist";
import popover from "@primeng/themes/lara/popover";
import progressbar from "@primeng/themes/lara/progressbar";
import progressspinner from "@primeng/themes/lara/progressspinner";
import radiobutton from "@primeng/themes/lara/radiobutton";
import rating from "@primeng/themes/lara/rating";
import ripple from "@primeng/themes/lara/ripple";
import scrollpanel from "@primeng/themes/lara/scrollpanel";
import select from "@primeng/themes/lara/select";
import selectbutton from "@primeng/themes/lara/selectbutton";
import skeleton from "@primeng/themes/lara/skeleton";
import slider from "@primeng/themes/lara/slider";
import speeddial from "@primeng/themes/lara/speeddial";
import splitbutton from "@primeng/themes/lara/splitbutton";
import splitter from "@primeng/themes/lara/splitter";
import stepper from "@primeng/themes/lara/stepper";
import steps from "@primeng/themes/lara/steps";
import tabmenu from "@primeng/themes/lara/tabmenu";
import tabs from "@primeng/themes/lara/tabs";
import tabview from "@primeng/themes/lara/tabview";
import tag from "@primeng/themes/lara/tag";
import terminal from "@primeng/themes/lara/terminal";
import textarea from "@primeng/themes/lara/textarea";
import tieredmenu from "@primeng/themes/lara/tieredmenu";
import timeline from "@primeng/themes/lara/timeline";
import toast from "@primeng/themes/lara/toast";
import togglebutton from "@primeng/themes/lara/togglebutton";
import toggleswitch from "@primeng/themes/lara/toggleswitch";
import toolbar from "@primeng/themes/lara/toolbar";
import tooltip from "@primeng/themes/lara/tooltip";
import tree from "@primeng/themes/lara/tree";
import treeselect from "@primeng/themes/lara/treeselect";
import treetable from "@primeng/themes/lara/treetable";
import virtualscroller from "@primeng/themes/lara/virtualscroller";
var lara_default = {
  ...base,
  components: {
    accordion,
    autocomplete,
    avatar,
    badge,
    blockui,
    breadcrumb,
    button,
    datepicker,
    card,
    carousel,
    cascadeselect,
    checkbox,
    chip,
    colorpicker,
    confirmdialog,
    confirmpopup,
    contextmenu,
    dataview,
    datatable,
    dialog,
    divider,
    dock,
    drawer,
    editor,
    fieldset,
    fileupload,
    iftalabel,
    floatlabel,
    galleria,
    iconfield,
    image,
    imagecompare,
    inlinemessage,
    inplace,
    inputchips,
    inputgroup,
    inputnumber,
    inputotp,
    inputtext,
    knob,
    listbox,
    megamenu,
    menu,
    menubar,
    message,
    metergroup,
    multiselect,
    orderlist,
    organizationchart,
    overlaybadge,
    popover,
    paginator,
    password,
    panel,
    panelmenu,
    picklist,
    progressbar,
    progressspinner,
    radiobutton,
    rating,
    scrollpanel,
    select,
    selectbutton,
    skeleton,
    slider,
    speeddial,
    splitter,
    splitbutton,
    stepper,
    steps,
    tabmenu,
    tabs,
    tabview,
    textarea,
    tieredmenu,
    tag,
    terminal,
    timeline,
    togglebutton,
    toggleswitch,
    tree,
    treeselect,
    treetable,
    toast,
    toolbar,
    virtualscroller,
    tooltip,
    ripple
  }
};
export { lara_default as default };
