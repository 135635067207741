<div class="landing-page">
  <div class="header-containers">
    <div class="left-items">
      <img src="assets/Themis.logo.svg" alt="Themis Logo" />
      <span class="title">THEMIS</span>
    </div>
    <div class="right-items">
      <app-button label="Login" (clickEvent)="login()" />
    </div>
  </div>

  <div class="content">
    <img
      src="../../../assets/landingPage.text.svg"
      width="450px"
      height="500px"
    />
    <img
      src="../../../assets/loadingPage.image.svg"
      width="700px"
      height="500px"
    />
  </div>
</div>
