import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class CatalogueService {
  constructor(private api: ApiService) {}

  async fetchCatalogueData(
    pageNo: number,
    pageSize: number,
    val: string
  ): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(
          `api/catalogue?pageNumber=${pageNo}&pageSize=${pageSize}&searchText=${val}`
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
