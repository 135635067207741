import { Component } from '@angular/core';
import { ButtonComponent } from '../../Components/button/button.component';
import { SharedModule } from '../../shared.module';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownType, TableCols, TaskData } from '../../types';
import { CATEGORIES, DEVICE_TYPE, TASK_COLS } from '../../../constants';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';
import { InputComponent } from '../../Components/input/input.component';
import { DropdownComponent } from '../../Components/dropdown/dropdown.component';
import { TaskService } from './task-management.service';

@Component({
  selector: 'app-task-management',
  providers: [MessageService],
  imports: [ButtonComponent, SharedModule, InputComponent, DropdownComponent],
  templateUrl: './task-management.component.html',
  styleUrl: './task-management.component.scss',
})
export class TaskManagementComponent {
  taskList: TaskData[] = [];
  isLoading: boolean = true;
  formData!: FormGroup;
  skeletonRows = new Array(10);
  onHover: number = 0;
  cols: TableCols[] = TASK_COLS;
  taskDialog: boolean = false;
  onSaveLoad: boolean = false;
  deleteDialog: boolean = false;
  edit: boolean = false;
  deviceType: DropDownType[] = DEVICE_TYPE;
  categories: DropDownType[] = CATEGORIES;
  rowData!: TaskData;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private service: TaskService
  ) {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      isReplace: ['', Validators.required],
      deviceType: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.taskList = await this.getTaskData();
  }

  async getTaskData(): Promise<TaskData[]> {
    try {
      const data = await this.service.fetchTaskData();
      if (data) this.isLoading = false;
      return this.sortByName(data);
    } catch (error: any) {
      this.isLoading = false;
      return [];
    }
  }

  private sortByName(zones: TaskData[]): TaskData[] {
    return zones.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  handleAddDialog(): void {
    this.formData.reset();
    this.taskDialog = true;
    this.edit = false;
  }

  closeDialog(): void {
    this.taskDialog = false;
    this.deleteDialog = false;
  }

  handleEdit(rowData: TaskData): void {
    this.taskDialog = true;
    this.formData.patchValue({
      ...rowData,
      isReplace: rowData.isReplace ? this.categories[0] : this.categories[1],
    });
    this.rowData = rowData;
    this.edit = true;
  }

  handleDelete(rowData: TaskData): void {
    this.deleteDialog = true;
    this.rowData = rowData;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    const { deviceType, name, isReplace } = this.formData.value;
    try {
      const payload = {
        deviceType,
        name: name.trim(),
        isReplace: isReplace.label === 'Replace' ? true : false,
      };
      const res = await this.service.createTaskData(payload);
      if (res) {
        this.taskList = await this.getTaskData();
        this.onSaveLoad = false;
        this.taskDialog = false;
        this.successToast('Task Created Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    const { deviceType, name, isReplace } = this.formData.value;
    try {
      const payload = {
        id: this.rowData.id,
        deviceType,
        name: name.trim(),
        isReplace: isReplace.label === 'Replace' ? true : false,
      };
      const res = await this.service.updateTaskData(payload);
      if (res) {
        this.taskList = await this.getTaskData();
        this.onSaveLoad = false;
        this.taskDialog = false;
        this.successToast('Task Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onDeleteTask(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.service.deleteTaskData(this.rowData.id);
      if (res) {
        this.taskList = await this.getTaskData();
        this.onSaveLoad = false;
        this.deleteDialog = false;
        this.successToast('Task Deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }
}
