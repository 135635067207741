import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { InputComponent } from '../../Components/input/input.component';
import { ButtonComponent } from '../../Components/button/button.component';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { DropDownType, TableCols } from '../../types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DEVICE_TYPE, INSTALLER_COLS } from '../../../constants';
import { InstallerService } from './installers.services';
import { MessageService } from 'primeng/api';
import { trimFormData } from '../common-methods';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-installers',
  templateUrl: './installers.component.html',
  styleUrl: './installers.component.scss',
  providers: [MessageService],
  imports: [
    SharedModule,
    InputComponent,
    ButtonComponent,
    SearchFieldComponent,
  ],
})
export class InstallersComponent {
  cols: TableCols[] = INSTALLER_COLS;
  installersList: any[] = [];
  display: boolean = false;
  formData!: FormGroup;
  onHover: number = 0;
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  edit: boolean = false;
  rowData: any;
  // installersListClone: any[] = [];
  onSaveLoad: boolean = false;
  first = 0;
  pageNumber: number = 0;
  currentPageSize: number = 10;
  installersCount: number = 0;
  searchSubject: Subject<string> = new Subject<string>();
  searchText: string | null = null;
  deviceList: DropDownType[] = DEVICE_TYPE;

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: InstallerService,
    private messageService: MessageService
  ) {
    this.setupSearch();
    this.initializeForms();
  }

  initializeForms(): void {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      UserEmailAddress: ['', [Validators.required, Validators.email]],
      deviceType: [[], Validators.required],
    });
  }

  async ngOnInit() {
    this.installersList = await this.getInstallerData(0, 10);
  }

  async getInstallerData(pageNo: number, pageSize: number): Promise<any[]> {
    try {
      const res = await this.service.fetchInstallerData(pageNo, pageSize, '');
      if (res) this.isLoading = false;
      // this.installersListClone = res.data;
      this.installersCount = res.totalCount;
      return res.data;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  private setupSearch() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((value: string) => {
      this.searchText = value;
      this.pageNumber = 0;
      this.first = 0;
      this.handleSearch(this.pageNumber, this.currentPageSize, value);
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  statusTag(
    status: string
  ):
    | 'success'
    | 'secondary'
    | 'info'
    | 'warn'
    | 'danger'
    | 'contrast'
    | undefined {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'INACTIVE':
        return 'danger';
      case 'INVITED':
        return 'warn';
      case 'InvitePending':
        return 'warn';
      default:
        return undefined;
    }
  }

  async handleSearch(
    pageNo: number,
    pageSize: number,
    val: string
  ): Promise<void> {
    try {
      if (val !== '') {
        const res = await this.service.fetchInstallerData(
          pageNo,
          pageSize,
          val
        );
        if (res) {
          this.installersList = res.data;
          this.installersCount = res.totalCount;
        }
      } else {
        this.installersList = await this.getInstallerData(pageNo, pageSize);
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }
  handleAddNew(): void {
    this.display = true;
    this.edit = false;
    this.initializeForms();
  }

  closeDialog(): void {
    this.display = false;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    const trimmedFormData = trimFormData(this.formData.value);
    try {
      const payload = {
        ...trimmedFormData,
        InstallerSource: 'Admin',
      };
      const res = await this.service.createInstallerData(payload);
      if (res) {
        this.display = false;
        this.installersList = await this.getInstallerData(0, 10);
        this.successToast('Installer Invited Successfully');
        this.onSaveLoad = false;
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  handleEdit(rowData: any): void {
    this.display = true;
    this.edit = true;
    this.formData.patchValue({
      name: rowData.name,
      UserEmailAddress: rowData.users[0].email,
      deviceType: rowData.deviceType,
    });
    this.rowData = rowData;
  }

  handleDelete(rowData: any): void {}

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const trimmedFormData = trimFormData(this.formData.value);
      const payload = {
        ...trimmedFormData,
        id: this.rowData.id,
        InstallerSource: 'Admin',
      };
      const res = await this.service.updateInstallerData(payload);
      if (res) {
        this.display = false;
        this.successToast('Installer Data Updated Successfully');
        this.installersList = await this.getInstallerData(0, 10);
        this.onSaveLoad = false;
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async pageChange(event: any): Promise<void> {
    this.pageNumber = event.first / event.rows;
    this.first = event.first;
    if (this.searchText) {
      this.handleSearch(this.pageNumber, this.currentPageSize, this.searchText);
    } else {
      this.installersList = await this.getInstallerData(
        this.pageNumber,
        this.currentPageSize
      );
    }
  }

  async onPageSizeChange(event: any) {
    this.currentPageSize = event.value;
    if (this.searchText) {
      this.handleSearch(this.pageNumber, event.value, this.searchText);
    } else {
      this.installersList = await this.getInstallerData(
        this.pageNumber,
        event.value
      );
    }
  }
}
