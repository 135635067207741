// src/presets/lara/card/index.ts
var card_default = {
  root: {
    background: "{content.background}",
    borderRadius: "{border.radius.lg}",
    color: "{content.color}",
    shadow: "0 .125rem .25rem rgba(0,0,0,.075)"
  },
  body: {
    padding: "1.5rem",
    gap: "0.75rem"
  },
  caption: {
    gap: "0.5rem"
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "700"
  },
  subtitle: {
    color: "{text.muted.color}"
  }
};
export { card_default as default };
