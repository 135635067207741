const ROUTES = {
  DASHBOARD: '',
  AGGREGATOR: 'aggregator',
  CATALOGUE: 'catalogue',
  SCHEME: 'scheme',
  CERTIFICATE_VALUE: 'certificate-value',
  SETTINGS: 'settings',
  MANAGE_ZONE: 'scheme/manage-zone',
  MANAGE_FIELD: 'scheme/manage-field',
  HAND_OUT_DOCUMENTS: 'scheme/hand-out-documents',
  FORMS: 'scheme/forms',
  SIMULATE_JOB: 'scheme/simulate-job',
  ACTIVITIES: 'scheme/:id/activities',
  FIELD_SETS: 'scheme/:schemeId/activity/:activityId/field-set',
  // PROJECT_VARIABLES: 'scheme/project-fields',
  INSTALLERS: 'installers',
  LOGIN: 'login',
  TASK_MANAGEMENT: 'task-management',
};

export default ROUTES;
