// src/presets/lara/iftalabel/index.ts
var iftalabel_default = {
  root: {
    color: "{form.field.float.label.color}",
    focusColor: "{form.field.float.label.focus.color}",
    invalidColor: "{form.field.float.label.invalid.color}",
    transitionDuration: "0.2s",
    positionX: "{form.field.padding.x}",
    top: "{form.field.padding.y}",
    fontSize: "0.75rem",
    fontWeight: "400"
  },
  input: {
    paddingTop: "1.875rem",
    paddingBottom: "{form.field.padding.y}"
  }
};
export { iftalabel_default as default };
