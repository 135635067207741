// config.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private baseUrl = window.location.origin;//'https://admin.themis.dev.dact.cloud';

  constructor(private http: HttpClient) { }

  getMsalConfig(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/config`);
  }
}
