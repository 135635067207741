import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
// import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TagModule } from 'primeng/tag';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfigService } from './Services/config-service';
import { Popover } from 'primeng/popover';
import { DatePickerModule } from 'primeng/datepicker';
import { Select } from 'primeng/select';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TableModule,
    DialogModule,
    // OverlayPanelModule,
    ReactiveFormsModule,
    MultiSelectModule,
    AutoCompleteModule,
    RadioButtonModule,
    TagModule,
    SkeletonModule,
    ToastModule,
    Popover,
    DatePickerModule,
    Select,
  ],
  exports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TableModule,
    DialogModule,
    // OverlayPanelModule,
    ReactiveFormsModule,
    MultiSelectModule,
    AutoCompleteModule,
    RadioButtonModule,
    TagModule,
    SkeletonModule,
    ToastModule,
    Popover,
    DatePickerModule,
    Select,
  ],
  providers: [MessageService, ConfirmationService, ConfigService],
})
export class SharedModule {}
