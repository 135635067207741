import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';
import { TaskType } from '../../types';

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(private api: ApiService) {}

  async fetchTaskData(): Promise<any> {
    try {
      const data: TaskType[] = await firstValueFrom(this.api.get(`api/tasks`));
      const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
      return sortedData;
    } catch (error) {
      throw error;
    }
  }

  async createTaskData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.post('api/tasks', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateTaskData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/tasks/${payload.id}`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteTaskData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.delete(`api/tasks/${id}`));
      return data;
    } catch (error) {
      throw error;
    }
  }
}
