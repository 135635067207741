import { Routes } from '@angular/router';
import ROUTES from '../routes.const';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { SchemeComponent } from './Pages/scheme/scheme.component';
import { ManageZoneComponent } from './Pages/scheme/manage-zone/manage-zone.component';
import { HandOutDocumentsComponent } from './Pages/scheme/hand-out-documents/hand-out-documents.component';
import { ManageFieldsComponent } from './Pages/scheme/manage-fields/manage-fields.component';
import { SimulateJobComponent } from './Pages/scheme/simulate-job/simulate-job.component';
import { AggregatorComponent } from './Pages/aggregator/aggregator.component';
import { CatalogueComponent } from './Pages/catalogue/catalogue.component';
import { CertificateValueComponent } from './Pages/certificate-value/certificate-value.component';
import { ActivitiesComponent } from './Pages/scheme/activities/activities.component';
import { FieldSetsComponent } from './Pages/scheme/field-sets/field-sets.component';
import { ProjectVariablesComponent } from './Pages/scheme/project-variables/project-variables.component';
import { InstallersComponent } from './Pages/installers/installers.component';
import { LandingPageComponent } from './Pages/landing-page/landing-page.component';
import { LayoutComponent } from './layout/layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { TaskManagementComponent } from './Pages/task-management/task-management.component';
import { FormsComponent } from './Pages/forms/forms.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: ROUTES.DASHBOARD,
        component: DashboardComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.AGGREGATOR,
        component: AggregatorComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.SCHEME,
        component: SchemeComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.CATALOGUE,
        component: CatalogueComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.CERTIFICATE_VALUE,
        component: CertificateValueComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.MANAGE_ZONE,
        component: ManageZoneComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.MANAGE_FIELD,
        component: ManageFieldsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.HAND_OUT_DOCUMENTS,
        component: HandOutDocumentsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.FORMS,
        component: FormsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.SIMULATE_JOB,
        component: SimulateJobComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.ACTIVITIES,
        component: ActivitiesComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.FIELD_SETS,
        component: FieldSetsComponent,
        canActivate: [MsalGuard],
      },
      // {
      //   path: ROUTES.PROJECT_VARIABLES,
      //   component: ProjectVariablesComponent,
      //   canActivate: [MsalGuard],
      // },
      {
        path: ROUTES.INSTALLERS,
        component: InstallersComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.TASK_MANAGEMENT,
        component: TaskManagementComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  { path: ROUTES.LOGIN, component: LandingPageComponent },
];
