import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(private api: ApiService) {}

  async fetchCertificateData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/Certificates/installer/${id}`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
