import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class SimulateJobService {
  constructor(private api: ApiService) {}

  async fetchSimulateJobData(payload: any): Promise<any> {
    try {
      const { deviceType, dwellingType, postalCode } = payload;
      const postal = postalCode.toString();
      const data = await firstValueFrom(
        this.api.get(
          `api/simulate-job?postalCode=${postal}&deviceType=${deviceType}&dwellingType=${dwellingType}`
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchFieldDatas(payload: any): Promise<any> {
    try {
      const { schemeId, activityId, date, postalCode } = payload;
      const data = await firstValueFrom(
        this.api.get(
          `api/simulate-job/fieldValues?postalCode=${postalCode}&schemeId=${schemeId}&activityId=${activityId}&date=${date}`
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async calculateFieldValue(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/simulate-job/calculate`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
